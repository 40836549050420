.resume-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
}

.download-button {
    border: none;
    color: white;
    border-radius: 50px;
    background: linear-gradient(to right, #d2941a, #eee395, #563b05);
    font-size: 22px;
    padding: 20px 60px;
    margin-bottom: 50px;
    cursor: pointer;
    transition: 0.3s;
}

.download-button:hover {
    transform: scale(1.05);
    transition: 0.3s;
}

.download-button:active {
    transform: scale(1.01);
    transition: 0.3s;
}

@media (max-width: 768px) {
    .resume-container {
        margin-top: 50px;
        gap: 15px;
    }

    .download-button {
        font-size: 18px;
        padding: 15px 40px;
    }

    .pdf-viewer {
        width: 90%;
        height: 600px;
    }
}

@media (max-width: 480px) {
    .resume-container {
        margin-top: 30px;
        gap: 10px;
    }

    .download-button {
        font-size: 16px;
        padding: 12px 30px;
    }

    .pdf-viewer {
        width: 100%;
        height: 500px;
    }
}