.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
  margin: 80px 170px;
  margin-top: 100px;
}
.contact-title {
  position: relative;
}
.contact-title h1 {
  padding: 0px 30px;
  font-size: 80px;
  font-weight: 600;
}
.contact-section {
  display: flex;
  gap: 150px;
}
.contact-left {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.contact-left h1 {
  font-size: 80px;
  font-weight: 700;
  background: linear-gradient(264deg, #df8908 -5.09%, #bf15ff 106.28%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact-left p {
  max-width: 550px;
  color: #d8d8d8;
  font-size: 20px;
  line-height: 35px;
}
.contact-details {
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: #d8d8d8;
  font-size: 22px;
}
.contact-detail {
  display: flex;
  align-items: center;
  gap: 20px;
}
.contact-right {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;
}
.contact-right label {
  color: #d8d8d8;
  font-size: 22px;
  font-weight: 500;
}
.contact-right input {
  border: none;
  width: 700px;
  height: 78px;
  padding-left: 20px;
  border-radius: 4px;
  background: #32323c;
  color: #a0a0a0;
  font-family: poppins;
}
.contact-right textarea {
  border: none;
  width: 675px;
  padding: 25px;
  border-radius: 4px;
  background: #32323c;
  color: #a0a0a0;
  font-family: poppins;
  font-size: 20px;
}
.contact-submit {
  border: none;
  color: white;
  border-radius: 50px;
  background: linear-gradient(to right, #d2941a, #eee395, #563b05);
  font-size: 22px;
  padding: 20px 60px;
  margin-bottom: 50px;
  cursor: pointer;
  transition: 0.3s;
}
.contact-submit:hover {
  transform: scale(1.05);
  transition: 0.3s;
}
.contact-submit:active {
  transform: scale(1.01);
  transition: 0.3s;
}
@media (max-width: 768px) {
  .contact {
      margin: 50px 20px; 
      gap: 40px; 
  }
  .contact-title h1 {
      font-size: 40px; 
  }
  .contact-section {
      flex-direction: column; 
      gap: 50px; 
  }
  .contact-left h1 {
      font-size: 50px; 
  }
  .contact-left p {
      font-size: 18px; 
      line-height: 28px; 
  }
  .contact-right input {
      width: 100%; 
      max-width: 90%; 
      height: 50px; 
      padding-left: 15px; 
  }
  .contact-right textarea {
      width: 100%; 
      max-width: 90%; 
      padding: 20px; 
      font-size: 18px; 
  }
  .contact-right {
      gap: 20px; 
  }
  .contact-submit {
      font-size: 20px; 
      padding: 15px 40px; 
      max-width: 90%; 
  }
}
@media (max-width: 480px) {
  .contact {
      gap: 30px; 
  }
  .contact-title h1 {
      font-size: 35px; 
  }
  .contact-left h1 {
      font-size: 40px; 
  }
  .contact-left p {
      font-size: 16px; 
      line-height: 26px; 
  }
  .contact-right input {
      height: 45px; 
      padding-left: 10px; 
  }
  .contact-right textarea {
      padding: 15px; 
      font-size: 16px; 
  }
  .contact-submit {
      font-size: 18px; 
      padding: 12px 35px; 
  }
}