* {
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px;
  background-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar img {
  width: 100px;
  height: auto;
  transition: margin-left 0.5s ease;
}

.nav-menu {
  display: flex;
  list-style: none;
  gap: 40px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
}

.nav-menu li {
  position: relative;
}

.nav-menu li a {
  cursor: pointer;
  position: relative;
  padding-bottom: 5px;
  text-decoration: none;
  color: white;
  transition: color 0.3s ease-in-out;
}

.nav-menu li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #b3914e;
  transition: width 0.3s ease-in-out;
}

.nav-menu li a:hover::after {
  width: 100%;
}

.nav-menu li a.active::after {
  width: 100%;
}

.nav-menu li a.active {
  color: #b3914e;
  font-weight: bold;
}

.nav-menu li a:focus {
  outline: 2px dashed #b3914e;
  outline-offset: 3px;
}

.nav-connect {
  padding: 15px 30px;
  border-radius: 50px;
  background: linear-gradient(to right, #d2941a, #eee395, #563b05);
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.nav-connect:hover {
  transform: scale(1.05);
  background: linear-gradient(to right, #b3914e, #ccc175, #8d7236);
}

.nav-connect:focus {
  outline: 2px dashed #b3914e;
  outline-offset: 3px;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.hamburger span {
  width: 100%;
  height: 3px;
  background-color: white;
  border-radius: 5px;
}

.hamburger.close {
  justify-content: center;
}

.hamburger.close span {
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: #b3914e;
  border-radius: 5px;
}

.hamburger.close span:first-child {
  transform: rotate(45deg);
}

.hamburger.close span:last-child {
  transform: rotate(-45deg);
}

@media (max-width: 1200px) {
  .navbar {
    padding: 20px 80px;
  }

  .navbar img {
    margin-left: 10px;
  }
}

@media (max-width: 992px) {
  .navbar {
    padding: 20px 60px;
  }

  .navbar img {
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 20px 40px;
  }

  .navbar img {
    margin-left: 10px;
  }

  .nav-connect {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .nav-menu {
    position: fixed;
    flex-direction: column;
    align-items: start;
    top: 0;
    gap: 20px;
    background-color: #1f0016;
    width: 300px;
    height: 100%;
    z-index: 9999;
    transition: right 0.5s ease;
    right: -300px;
    padding-top: 60px;
  }

  .nav-menu li {
    font-size: 24px;
    padding-left: 100px;
    flex-direction: row;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 20px 20px;
  }

  .navbar img {
    margin-left: 5px;
  }

  .nav-menu {
    width: 60%;
    right: -100%;
    padding-top: 60px;
  }

  .nav-menu li {
    font-size: 20px;
    padding-left: 50px;
  }

  .nav-connect {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 40px;
  }
}

@media (max-width: 375px) {
  .navbar {
    padding: 20px 10px;
  }

  .navbar img {
    margin-left: 5px;
  }

  .nav-menu {
    width: 200px;
    right: -200px;
    padding-top: 60px;
  }

  .nav-menu li {
    font-size: 18px;
    padding-left: 40px;
  }
}