.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.blog-title h1 {
  font-size: 80px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
}
.blog-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
.blog-post {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
.blog-post:hover {
  transform: scale(1.05);
}
.blog-post h2 {
  font-size: 24px;
  font-weight: 600;
}
.blog-post p {
  font-size: 18px;
  color: #777;
}
.blog-date {
  font-size: 16px;
  color: #aaa;
  text-align: right;
}
@media (max-width: 768px) {
  .blog {
    margin-top: 50px; 
    padding: 0 20px; 
  }
  .blog-title h1 {
    font-size: 50px; 
    margin-bottom: 20px; 
  }
  .blog-container {
    gap: 20px; 
  }
  .blog-post {
    padding: 15px; 
  }
  .blog-post h2 {
    font-size: 20px; 
  }
  .blog-post p {
    font-size: 16px; 
  }
  .blog-date {
    font-size: 14px; 
  }
}
@media (max-width: 480px) {
  .blog {
    margin-top: 30px; 
  }
  .blog-title h1 {
    font-size: 40px; 
  }
  .blog-container {
    gap: 15px; 
  }
  .blog-post {
    padding: 10px; 
  }
  .blog-post h2 {
    font-size: 18px; 
  }
  .blog-post p {
    font-size: 14px; 
  }
  .blog-date {
    font-size: 12px; 
  }
}