.skills {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 100px;
  width: 100%; 
  max-width: 800px; 
  margin: 0 auto; 
  position: relative; 
}
.skill-title {
  position: relative;
  text-align: center;
}
.skill-title h1 {
  font-size: 80px;
  font-weight: 600;
  z-index: 1; 
  margin: 0;
}
.skill {
  display: flex;
  gap: 50px;
  align-items: center;
  transition: 0.3s;
  padding-left: 50px;
  max-width: 100%; 
  box-sizing: border-box; 
}
.skill p {
  min-width: 150px;
  font-size: 24px;
  font-weight: 500;
}
.skill hr {
  outline: none;
  border: none;
  width: 50%; 
  height: 8px;
  border-radius: 50px;
  background: linear-gradient(264deg, #df8908 -5.09%, #bf15ff 106.28%);
}
.skill:hover {
  transform: scale(1.03);
  transition: 0.3s;
  max-width: 100%; 
  box-sizing: border-box; 
}
@media (max-width: 768px) {
  .skills {
      margin-top: 50px; 
  }
  .skill-title h1 {
      font-size: 50px; 
  }
  .skill {
      flex-direction: column; 
      padding-left: 0; 
      align-items: flex-start; 
  }
  .skill p {
      min-width: auto; 
      font-size: 18px; 
      padding-left: 20px; 
  }
  .skill hr {
      width: 70%; 
      margin-left: 20px;
  }
}
@media (max-width: 480px) {
  .skill-title h1 {
      font-size: 40px; 
  }
  .skill p {
      font-size: 16px; 
  }
  .skill {
    width: 70%;
      gap: 15px; 
  }
  .skill hr {
    width: 70%; 
    margin-left: 20px;
}
}