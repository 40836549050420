.footer {
  margin: 50px 170px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-top-left p {
  font-size: 20px;
  max-width: 400px;
}
.footer-top-right {
  display: flex;
  align-items: center;
  gap: 35px;
}
.footer-email-input {
  display: flex;
  gap: 35px;
  padding: 20px 30px;
  border-radius: 50px;
  background: #32323b;
  padding-right: 80px;
}
.footer-email-input input {
  border: none;
  outline: none;
  background: transparent;
  color: #a0a0a0;
  font-family: poppins;
  font-size: 18px;
}
.footer-subscribe {
  font-size: 20px;
  padding: 22px 50px;
  border-radius: 50px;
  background: linear-gradient(to right, #d2941a, #eee395, #563b05);
  cursor: pointer;
  transition: 0.3s;
}
.footer-subscribe:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
.footer-email-input:hover {
  border: 1px solid white;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.footer-bottom-right {
  display: flex;
  gap: 50px;
  margin-bottom: 50px;
}
.footer-top-left img {
  width: 100px; 
  height: auto; 
}
@media (max-width: 768px) {
  .footer {
    margin: 50px 20px;
    gap: 20px;
  }
  .footer-top {
    flex-direction: column;
    gap: 20px;
  }
  .footer-top-left p {
    font-size: 18px;
    max-width: 100%;
    text-align: center;
  }
  .footer-top-right {
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
  .footer-email-input {
    padding: 15px 20px;
    gap: 15px;
    padding-right: 20px;
    width: 70%;
  }
  .footer-subscribe {
    font-size: 18px;
    padding: 15px 40px;
  }
  .footer-bottom {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
  .footer-bottom-right {
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
  }
  .footer-top-left img {
    width: 79px; 
    height: auto; 
  }
}
@media (max-width: 480px) {
  .footer {
    margin: 30px 10px; 
    gap: 15px;
  }
  .footer-top {
    gap: 15px; 
  }
  .footer-top-left img {
    width: 79px; 
    height: auto; 
  }
  .footer-top-left p {
    font-size: 16px; 
    max-width: 100%;
  }
  .footer-top-right {
    gap: 10px; 
  }
  .footer-email-input {
    padding: 10px 15px; 
    gap: 10px;
    width: 83%;
  }
  .footer-subscribe {
    font-size: 16px; 
    padding: 10px 20px; 
  }
  .footer-bottom {
    font-size: 16px; 
    gap: 10px;
  }
  .footer-bottom-right {
    gap: 20px; 
  }
}