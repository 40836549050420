.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 100px;
  position: relative;
}
.project-title {
  position: relative;
  text-align: center;
}
.project-title h1 {
  font-size: 80px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  margin: 0;
}
.project-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 20px;
}
.project-card {
  margin-top: 70px;
  width: 300px; 
  height: 400px; 
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}
.project-card:hover {
  transform: scale(1.05);
  border-color: blueviolet;
}
.project-image {
  width: 100%;
  height: 200px; 
  object-fit: cover;
}
.project-details {
  padding: 15px;
  flex-grow: 1; 
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}
.project-details h3 {
  font-size: 22px;
  margin: 10px 0;
  background: linear-gradient(264deg, #df8908 -5.09%, #bf15ff 106.28%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.project-details p {
  font-size: 18px;
  color: #777;
  flex-grow: 1; 
}
.project-details a {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 50px;
  background: linear-gradient(264deg, #df8908 -5.09%, #bf15ff 106.28%);
  color: white;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}
.project-details a:hover {
  background: linear-gradient(264deg, #bf15ff -5.09%, #df8908 106.28%);
  transform: scale(1.05);
}
@media (max-width: 768px) {
  .project-title h1 {
    font-size: 40px; 
  }
  .project-container {
    gap: 20px; 
    padding: 10px; 
  }
  .project-card {
    width: 70%; 
    height: auto; 
    margin-top: 30px; 
  }
  .project-details h3 {
    font-size: 18px; 
  }
  .project-details p {
    font-size: 16px; 
  }
  .project-details a {
    font-size: 14px; 
    padding: 8px 16px; 
  }
}
@media (max-width: 480px) {
  .project-title h1 {
    font-size: 30px; 
  }
  .project-container {
    gap: 10px; 
    padding: 5px; 
  }
  .project-card {
    width: 70%; 
    margin-top: 20px; 
  }
  .project-details h3 {
    font-size: 16px; 
  }
  .project-details p {
    font-size: 14px; 
  }
  .project-details a {
    font-size: 12px; 
    padding: 6px 12px; 
  }
}